import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// get select by id
export const getSelectApi = async (id) => {
  return await (
    await axios.get(`${BASE_URL}/select/${id}`)
  ).data;
};

// create select
export const createSelectApi = async ({ text, questionId }) => {
  return await (
    await axios.post(`${BASE_URL}/select`, {
      text,
      questionId,
    })
  ).data;
};

// delete select
export const deleteSelectApi = async (id) => {
  return await (
    await axios.delete(`${BASE_URL}/select/${id}`)
  ).data;
};

// update select
export const updateSelectApi = async ({ id, text }) => {
  return await (
    await axios.put(`${BASE_URL}/select/${id}`, {
      text,
    })
  ).data;
};
