export const queryKey = {
  blockKey: {
    getBlocks: "getBlocks",
    getBlock: "getBlock",
    getFixedBlocks: "getFixedBlocks",
    getNotFixedBlocks: "getNotFixedBlocks",
  },
  chatKey: {
    getAllMessages: "getAllMessages",
  },
};
