import React, { useState } from "react";
import styled from "styled-components";
import Block from "../components/Block";
import { useMutation, useQuery } from "react-query";
import { queryKey } from "../utils/queryKey";
import {
  getBlocksApi,
  getFixedBlocksApi,
  getNotFixedBlocksApi,
} from "../apis/blockApi";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.png";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
`;
const InnerContainer = styled.div`
  background-color: #f7f8ff;
  min-height: 100vh;
  width: 600px;
  padding: 0 0.8rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.div`
  /* padding: 2rem 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  h1 {
    font-size: 2rem;
    color: #0043a8;
    font-weight: 600;
  }
  img {
    width: 15rem;
  }
`;
const FixedBlocks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 두 개의 열 생성 */
  grid-template-rows: auto auto auto; /* 세 개의 행 생성 */
  gap: 10px; /* 요소들 사이의 간격 */
  .item1,
  .item4 {
    grid-column: span 2; /* 첫 번째와 네 번째 요소는 두 열을 차지 */
  }
  .item2,
  .item3 {
    grid-column: span 1; /* 두 번째와 세 번째 요소는 한 열만 차지 */
  }
`;
const NonFixedBlocks = styled.div`
  margin-top: 10px;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 10px;
`;

function Home() {
  const {
    isLoading,
    data: fixedBlocks,
    error,
  } = useQuery(queryKey.blockKey.getBlocks, getFixedBlocksApi);
  const navigate = useNavigate();

  console.log(fixedBlocks);

  const { data: nonFixedBlocks, refetch: nonFixedBlockRefetch } = useQuery(
    queryKey.blockKey.getNotFixedBlocks,
    getNotFixedBlocksApi
  );
  console.log({ nonFixedBlocks });

  return (
    <Container>
      <InnerContainer>
        <Title>
          {/* <h1>WASHAUL</h1> */}
          <img src={logo} alt="washaul" />
        </Title>
        <FixedBlocks>
          {isLoading && <div>로딩중...</div>}
          {fixedBlocks?.map((block, index) => (
            <div
              key={block.id}
              className={`item${index + 1}`}
              onClick={() => {
                index === 3 && window.open(block.link);
              }}
            >
              <Block
                title={block.title}
                description={block.description}
                blockId={block.id}
                assistantId={block.assistantId}
                isFixed={true}
                link={block.link || ""}
                iconType={
                  index === 0
                    ? "chatbot"
                    : index === 1
                    ? "siren"
                    : index === 2
                    ? "carCrash"
                    : index === 3
                    ? "channelTalk"
                    : ""
                }
              />
            </div>
          ))}
        </FixedBlocks>
        <NonFixedBlocks>
          {" "}
          {nonFixedBlocks?.map((block) => {
            return (
              <div key={block.id}>
                <Block
                  title={block.title}
                  description={block.description}
                  blockId={block.id}
                  assistantId={block.assistantId}
                  isFixed={false}
                  isAdmin={false}
                  link={block.link || ""}
                />
              </div>
            );
          })}
        </NonFixedBlocks>
      </InnerContainer>
    </Container>
  );
}

export default Home;
