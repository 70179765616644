import React, { useState } from "react";
import styled from "styled-components";
import Block from "../../components/Block";
import { useMutation, useQuery } from "react-query";
import { queryKey } from "../../utils/queryKey";
import {
  createBlockApi,
  getBlocksApi,
  getFixedBlocksApi,
  getNotFixedBlocksApi,
} from "../../apis/blockApi";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
  .addBlock {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    font-size: 1.2rem;
    font-weight: 400;
    h3 {
      width: max-content;
      cursor: pointer;
      padding: 0.5rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
const InnerContainer = styled.div`
  background-color: #f7f8ff;
  min-height: 100vh;
  width: 600px;
  padding: 0 0.8rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.div`
  /* padding: 2rem 0; */
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  h1 {
    font-size: 2rem;
    color: #0043a8;
    font-weight: 600;
  }
  img {
    width: 15rem;
  }
  p.goHome {
    margin-top: 0.5rem;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const FixedBlocks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 두 개의 열 생성 */
  grid-template-rows: auto auto auto; /* 세 개의 행 생성 */
  gap: 10px; /* 요소들 사이의 간격 */
  .item1,
  .item4 {
    grid-column: span 2; /* 첫 번째와 네 번째 요소는 두 열을 차지 */
  }
  .item2,
  .item3 {
    grid-column: span 1; /* 두 번째와 세 번째 요소는 한 열만 차지 */
  }
`;
const NonFixedBlocks = styled.div`
  margin-top: 10px;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 10px;
  word-break: break-all;
  white-space: pre-wrap;
`;

const ModalContainer = styled.form`
  padding: 1rem;
  .header {
    display: flex;
    justify-content: flex-end;
    .icon {
      cursor: pointer;
      scale: 1.4;
    }
  }
  .inputGroup {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    h3 {
      width: 3rem;
      font-weight: 500;
    }
    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
  .completeBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    button {
      padding: 0.5rem 1rem;
    }
  }
`;

function Admin() {
  const {
    isLoading,
    data: fixedBlocks,
    error,
    refetch: fixedBlockRefetch,
  } = useQuery(queryKey.blockKey.getBlocks, getFixedBlocksApi);
  const navigate = useNavigate();

  console.log({ fixedBlocks });

  const { data: nonFixedBlocks, refetch: nonFixedBlockRefetch } = useQuery(
    queryKey.blockKey.getNotFixedBlocks,
    getNotFixedBlocksApi
  );
  console.log({ nonFixedBlocks });

  // Modal
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: window.innerWidth > 768 ? "50vw" : "80vw",
      zIndex: "12",
      // height: `50vh`,
      padding: 0,
      borderColor: "#000",
      backgroundColor: "rgb(245,243,243)",
    },
  };
  const [addBlockModalIsOpen, setAddModalOpen] = useState(false);
  const openAddModal = () => {
    setAddModalOpen(true);
  };
  const closeAddModal = () => {
    console.log("dododo");
    setAddModalOpen(false);
  };

  // create block
  const { mutate: createBlock } = useMutation(createBlockApi, {
    onSuccess: (res) => {
      console.log(res);
      alert("추가되었습니다.");
      fixedBlockRefetch();
      nonFixedBlockRefetch();
      closeAddModal();
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const onSubmitBlock = (inputData) => {
    reset();
    createBlock(inputData);
  };

  return (
    <Container>
      <InnerContainer>
        <Title>
          {/* <h1>WASHAUL</h1> */}
          <img src={logo} alt="washaul" />
          <h1>관리자 페이지</h1>
          <p className="goHome" onClick={() => navigate("/")}>
            메인 페이지로 이동
          </p>
        </Title>
        <FixedBlocks>
          {isLoading && <div>로딩중...</div>}
          {fixedBlocks?.map((block, index) => (
            <div key={block.id} className={`item${index + 1}`}>
              <Block
                title={block.title}
                description={block.description}
                blockId={block.id}
                assistantId={block.assistantId}
                isFixed={true}
                isAdmin={true}
                link={block.link || ""}
                questions={block.questions || []}
                iconType={
                  index === 0
                    ? "chatbot"
                    : index === 1
                    ? "siren"
                    : index === 2
                    ? "carCrash"
                    : index === 3
                    ? "channelTalk"
                    : ""
                }
              />
            </div>
          ))}
        </FixedBlocks>
        <NonFixedBlocks>
          {nonFixedBlocks?.map((block) => {
            return (
              <div key={block.id} className="block">
                <Block
                  title={block.title}
                  description={block.description}
                  blockId={block.id}
                  assistantId={block.assistantId}
                  isFixed={false}
                  isAdmin={true}
                  link={block.link || ""}
                  d
                />
              </div>
            );
          })}
        </NonFixedBlocks>
        <div className="addBlock">
          <h3 onClick={openAddModal}>블록 추가</h3>
        </div>
      </InnerContainer>
      <Modal
        isOpen={addBlockModalIsOpen}
        style={customStyles}
        onRequestClose={closeAddModal}
      >
        <ModalContainer onSubmit={handleSubmit(onSubmitBlock)}>
          <div className="header">
            <IoIosCloseCircleOutline className="icon" onClick={closeAddModal} />
          </div>
          <div className="inputBox">
            <div className="inputGroup">
              <h3>제목</h3>
              <input type="text" {...register("title")} autoComplete="off" />
            </div>
            <div className="inputGroup">
              <h3>설명</h3>
              <input
                type="text"
                {...register("description")}
                autoComplete="off"
              />
            </div>

            <div className="inputGroup">
              <h3>링크</h3>
              <input type="text" {...register("link")} autoComplete="off" />
            </div>
          </div>
          <div className="completeBtn">
            <button>완료</button>
          </div>
        </ModalContainer>
      </Modal>
    </Container>
  );
}

export default Admin;
