import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { queryKey } from "../../utils/queryKey";
import { getBlockApi } from "../../apis/blockApi";
import {
  createQuestionApi,
  deleteQuestionApi,
  updateQuestionApi,
} from "../../apis/question";
import { set, useForm } from "react-hook-form";
import Modal from "react-modal";
import {
  createSelectApi,
  deleteSelectApi,
  updateSelectApi,
} from "../../apis/select";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 6rem 1rem;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  .titleGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    h1.title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    .homeBtn {
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
    }
  }
`;
const Question = styled.div`
  margin-bottom: 2.5rem;
  form.titleGroup {
    display: flex;
    align-items: center;
    input {
      width: 80%;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }
  .questionText {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  button {
    background-color: transparent;
    border: none;
    color: #818181;
    display: block;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn {
    background-color: transparent;
    border: none;
    color: #818181;
    font-size: 0.9rem;
    margin-right: 0.5rem;
    display: block;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .editSelects {
    margin-left: 1rem;
  }
`;
const Select = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.5rem;
  .selectText {
    font-size: 1rem;
    font-weight: 400;
  }

  form.selectGroup {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    input {
      max-width: 6rem;
    }
    .btnGroup {
      display: flex;
      align-items: center;
    }
  }

  /*  */
  .addSelectForm {
    /* display: flex; */
    .addSelectInput {
      width: 100%;
    }
    button {
      /* nowrap */
      white-space: nowrap;
      margin-left: 0.3rem;
    }
  }
`;
const AddQuestion = styled.form`
  input {
    width: 80%;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
`;

function EditBlock() {
  const { register, handleSubmit, reset, getValues, resetField, setValue } =
    useForm();
  const { blockId } = useParams();
  console.log({ blockId });
  const { data: blockData } = useQuery(queryKey.blockKey.getBlock, () =>
    getBlockApi(blockId)
  );
  console.log({ blockData });

  // add question
  const queryClient = useQueryClient();
  const { mutate: addQuestion } = useMutation(createQuestionApi, {
    onSuccess: (res) => {
      console.log("addQuestion success", res);
      alert("완료되었습니다.");
      queryClient.invalidateQueries(queryKey.blockKey.getBlock);
    },
    onError: (err) => {
      console.log("addQuestion error", err);
    },
  });

  // edit question
  const { mutate: editQuestion } = useMutation(updateQuestionApi, {
    onSuccess: (res) => {
      console.log("editQuestion success", res);
      alert("수정이 완료되었습니다.");
      setSelectedQuestion(null);
      queryClient.invalidateQueries(queryKey.blockKey.getBlock);
    },
    onError: (err) => {
      console.log("editQuestion error", err);
    },
  });

  // delete question
  const { mutate: deleteQuestion } = useMutation(deleteQuestionApi, {
    onSuccess: (res) => {
      console.log("deleteQuestion success", res);
      alert("삭제가 완료되었습니다.");
      queryClient.invalidateQueries(queryKey.blockKey.getBlock);
    },
    onError: (err) => {
      console.log("deleteQuestion error", err);
    },
  });

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  console.log({ selectedQuestion });
  const onEditQuestion = (inputData) => {
    editQuestion({
      text: inputData[`questionText_${selectedQuestion}`],
      id: inputData[`questionId_${selectedQuestion}`],
    });
  };
  const onAddQuestion = (inputData) => {
    console.log({ inputData });
    reset();
    setValue("addQuestionText", "");
    addQuestion({
      text: inputData.addQuestionText,
      blockId: blockId,
    });
  };

  // add select
  const { mutate: addSelect } = useMutation(createSelectApi, {
    onSuccess: (res) => {
      console.log("addSelect success", res);
      reset();
      alert("완료되었습니다.");
      setSelectedQuestion(null);
      queryClient.invalidateQueries(queryKey.blockKey.getBlock);
    },
    onError: (err) => {
      console.log("addSelect error", err);
    },
  });
  const onAddSelect = (inputData) => {
    console.log({ inputData });
    reset();
    setValue(`selectText_${selectedQuestion}`, "");
    resetField(`selectText_${selectedQuestion}`);
    addSelect({
      text: inputData[`selectText_${selectedQuestion}`],
      questionId: selectedQuestion,
    });
  };

  // delete select
  const { mutate: deleteSelect } = useMutation(deleteSelectApi, {
    onSuccess: (res) => {
      console.log("deleteSelect success", res);
      alert("완료되었습니다.");
      setSelectedQuestion(null);
      queryClient.invalidateQueries(queryKey.blockKey.getBlock);
    },
    onError: (err) => {
      console.log("deleteSelect error", err);
    },
  });

  // edit select
  const { mutate: editSelect } = useMutation(updateSelectApi, {
    onSuccess: (res) => {
      console.log("editSelect success", res);
      alert("완료되었습니다.");
      setSelectedSelect(null);
      queryClient.invalidateQueries(queryKey.blockKey.getBlock);
    },
    onError: (err) => {
      console.log("editSelect error", err);
    },
  });
  const [selectedSelect, setSelectedSelect] = useState(null);
  const onEditSelect = (inputData) => {
    console.log({ inputData });
    editSelect({
      text: inputData[`selectTextEdit_${selectedSelect}`],
      id: selectedSelect,
    });
  };
  console.log({ selectedSelect, getvalues: getValues() });

  const navigate = useNavigate();

  return (
    <Container>
      <div>
        <div className="titleGroup">
          <h1 className="title">
            {"<"}질문, 선택지 수정{">"}
          </h1>
          <div className="homeBtn" onClick={() => navigate("/admin")}>
            어드민으로 이동
          </div>
        </div>
        {blockData?.block.questions.map((question, i) => (
          <Question key={question.id}>
            <form
              className="titleGroup"
              onSubmit={handleSubmit(onEditQuestion)}
            >
              <input
                className="questionText"
                type="text"
                defaultValue={`${question.text}`}
                {...register(`questionText_${question.id}`)}
                autoComplete="off"
              />
              <input
                type="hidden"
                defaultValue={question.id}
                {...register(`questionId_${question.id}`)}
              />
              <button
                className="editQuestionBtn btn"
                onClick={() => {
                  setSelectedQuestion(question.id);
                }}
              >
                수정
              </button>
              <div
                className="deleteQuestionBtn btn"
                onClick={() => {
                  deleteQuestion(question.id);
                }}
              >
                삭제
              </div>
            </form>
            <Select>
              {question?.selects &&
                question?.selects.map((select) => (
                  <form
                    className="selectGroup"
                    key={select.id}
                    onSubmit={handleSubmit(onEditSelect)}
                  >
                    <input
                      type="text"
                      defaultValue={`${select.text}`}
                      {...register(`selectTextEdit_${select.id}`)}
                    />
                    <div className="btnGroup">
                      <button
                        className="btn"
                        onClick={() => {
                          setSelectedSelect(select.id);
                        }}
                      >
                        수정
                      </button>
                      <div
                        className="btn"
                        onClick={() => deleteSelect(select.id)}
                      >
                        삭제
                      </div>
                    </div>
                  </form>
                ))}
              <form
                className="addSelectForm"
                onSubmit={handleSubmit(onAddSelect)}
              >
                <input
                  type="text"
                  className="addSelectInput"
                  {...register(`selectText_${question.id}`)}
                  autoComplete="off"
                />
                <button
                  className="btn"
                  onClick={() => {
                    setSelectedQuestion(question.id);
                  }}
                >
                  추가
                </button>
              </form>
            </Select>
          </Question>
        ))}
      </div>
      <AddQuestion onSubmit={handleSubmit(onAddQuestion)}>
        <input
          type="text"
          className="questionInput"
          placeholder="질문 내용을 입력하세요"
          {...register("addQuestionText")}
          autoComplete="off"
        />
        <button>추가</button>
      </AddQuestion>
    </Container>
  );
}

export default EditBlock;
