import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { chatApi, deleteThreadApi, getAllMessagesApi } from "../apis/chat";
import { queryKey } from "../utils/queryKey";
import { LuRefreshCw } from "react-icons/lu";
import { IoMdSend } from "react-icons/io";
import { useForm } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { getBlockApi } from "../apis/blockApi";
import { useParams } from "react-router-dom";
import axios from "axios";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;
const ChatHeader = styled.div`
  background-color: ${(props) => props.theme.keyColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  width: ${(props) => props.theme.mobileWidth};
  overflow: hidden;
  z-index: 9;
  @media screen and (max-width: ${(props) => props.theme.mobileWidth}) {
    width: 100%;
  }
  height: ${(props) => props.theme.headerHeight};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  h1.title {
    color: #fff;
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
  }
  .refreshIcon {
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .deleting {
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation: rotate 1.5s linear infinite;
  }
`;
const InnerContainer = styled.div`
  background-color: #f7f8ff;
  min-height: 100vh;
  width: ${(props) => props.theme.mobileWidth};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @media screen and (max-width: ${(props) => props.theme.mobileWidth}) {
    width: 100%;
  }
`;
const ChatContainer = styled.form`
  overflow: hidden;
`;
const ChatContents = styled.div`
  /* background-color: red; */
  max-height: calc(
    98vh -
      (
        ${(props) => props.theme.headerHeight} +
          ${(props) => props.theme.footerHeight}
      )
  );
  overflow-y: scroll;
  margin-top: ${(props) => props.theme.headerHeight};
`;
const ChatMessage = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.className === "user" ? "flex-end" : "flex-start"};
  margin: 1rem;
  font-size: 0.9rem;
  .loading {
    scale: 0.8;
  }
  .message {
    border-radius: 0.5rem;
    padding: 0.5rem 0.7rem;
    line-height: 1.1rem;
  }
  &.user {
    .message {
      background-color: ${(props) => props.theme.keyColor};
      color: #fff;
      /* 행간 넓히기 */
    }
  }
  &.assistant {
    .message {
      background-color: #d7d7d7;
      /* padding: 1rem; */
      /* 행간 넓히기 */
    }
  }
`;
const ChatInput = styled.div`
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  background-color: #fff;
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 1.5vh auto;
  width: ${(props) => props.theme.mobileWidth};
  height: ${(props) => props.theme.footerHeight};
  @media screen and (max-width: ${(props) => props.theme.mobileWidth}) {
    width: 95%;
  }
  .sendIcon {
    color: ${(props) => props.theme.keyColor};
    font-size: 1.5rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }
  input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    background-color: inherit;
  }
`;

const ChatQuestion = styled.div`
  font-size: 0.9rem;
`;
const Select = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-row-gap: 0.5rem;
  grid-template-columns: auto auto auto;
  justify-content: center;
  margin-bottom: 2rem;
  .selectBox {
    background-color: transparent;
    text-align: center;
    padding: 0.6rem;
    border-radius: 0.5rem;
    border: 1.5px solid ${(props) => props.theme.keyColor};
    color: ${(props) => props.theme.keyColor};
    font-weight: 600;
    margin: 0 0.5rem;
    &.selected {
      background-color: ${(props) => props.theme.keyColor};
      color: #fff;
    }
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.keyColor};
      color: #fff;
    }
  }
`;

function Chat() {
  const { register, handleSubmit, reset, getValues, resetField } = useForm();
  const blockId = useParams()?.blockId;
  const [currentQuery, setCurrentQuery] = useState(getValues("query") || "");

  // 로컬스토리지에서 threadId 가져오기
  // let threadId = localStorage.getItem(`threadId_${blockId}`) || "";
  const [threadId, setThreadId] = useState(
    localStorage.getItem(`threadId_${blockId}`) || ""
  );
  // console.log({ threadId });

  const {
    isLoading,
    data: messages,
    error,
    refetch,
  } = useQuery([queryKey.chatKey.getAllMessages, threadId], () =>
    getAllMessagesApi(threadId)
  );

  const queryClient = useQueryClient();
  const { mutate: postChat, isLoading: runIsGoing } = useMutation(chatApi, {
    onSuccess: (res) => {
      console.log(res);
      const newThreadId = res.threadId;
      localStorage.setItem(`threadId_${blockId}`, newThreadId);
      if (threadId !== newThreadId) {
        setThreadId(newThreadId);
      }
      queryClient.setQueryData(
        [queryKey.chatKey.getAllMessages, threadId],
        (oldData) => {
          console.log({ oldData });
          return {
            messages: [
              ...oldData.messages,
              {
                role: "user",
                content: res.userMessage,
              },
              {
                role: "assistant",
                content: res.AImessage,
              },
            ],
          };
        }
      );
      // reset();
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const { mutate: deleteThread, isLoading: deletingIsGoing } = useMutation(
    deleteThreadApi,
    {
      onSuccess: (res) => {
        console.log("deleteThread", res);
        localStorage.removeItem(`threadId_${blockId}`);
        reset();
        setThreadId("");
        // refetch();
        queryClient.invalidateQueries([
          queryKey.chatKey.getAllMessages,
          threadId,
        ]);
      },
      onError: (err) => {
        console.log(err);
        localStorage.removeItem(`threadId_${blockId}`);
      },
    }
  );

  // form 관련
  const onSubmitChat = (inputData) => {
    // console.log(inputData);
    const { query } = inputData;
    const data = {
      query,
      blockId,
      threadId,
      additionalInfo,
    };
    setCurrentQuery(query);
    reset();
    postChat(data);
  };

  // console.log({ messages });

  // 블록 정보
  const { data: blockData } = useQuery(queryKey.blockKey.getBlock, () =>
    getBlockApi(blockId)
  );
  // console.log({ blockData });

  // 사전 질문 관련
  const questions = blockData && blockData.block.questions;
  console.log({ questions });
  const [showQuestion, setShowQuestion] = useState([
    questions && questions[0]?.id,
  ]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selectId, setSelectId] = useState([]);
  console.log({ selectId });

  const [showAlertMessage, setShowAlertMessage] = useState(false);

  // ----------------------------------------------------------------
  // new Chatbot

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
    resetField: resetField2,
  } = useForm();

  const [chatHistory, setChatHistory] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [chatIsLoading, setChatIsLoading] = useState(false);
  const sendMessage = async (message) => {
    setChatIsLoading(true);
    console.log({
      chat_history: chatHistory,
      question: currentQuestion,
      additional_info: additionalInfo,
    });

    const response = await (
      await axios.post(
        "https://bot.allotter.store/chat",
        {
          chat_history: [...chatHistory, { role: "user", content: message }],
          question: message,
          additional_info: additionalInfo,
        }
        // {
        //   withCredentials: true,
        // }
      )
    ).data;
    setChatIsLoading(false);
    setCurrentQuestion("");
    setChatHistory([
      ...chatHistory,
      { role: "user", content: message },
      {
        role: "assistant",
        content: response,
      },
    ]);
  };
  const onSubmitChat2 = (inputData) => {
    // console.log(inputData);
    const { question } = inputData;
    setCurrentQuestion(question);
    setChatHistory([
      ...chatHistory,
      {
        role: "user",
        content: question,
      },
    ]);
    reset2();
    sendMessage(question);
  };
  // const [deleting, setDeleting] = useState(false);
  const onClickDelete = () => {
    // setDeleting(true);
    if (threadId) {
      localStorage.removeItem(`threadId_${blockId}`);
      setChatHistory([]);
      setCurrentQuestion("");
    }
    // deleteThread(threadId);
  };

  // 항상 맨 아래로 스크롤되게
  const scrollableElementRef = useRef(null);
  useEffect(() => {
    if (scrollableElementRef.current) {
      scrollableElementRef.current.scrollTop =
        scrollableElementRef.current.scrollHeight;
    }
  }, [chatHistory, onSubmitChat2]);

  return (
    <Container>
      <InnerContainer>
        <ChatHeader>
          <h1 className="title">{blockData?.block.title}</h1>
          {/* <LuRefreshCw className={`refreshIcon `} onClick={onClickDelete} /> */}
        </ChatHeader>
        <ChatContainer onSubmit={handleSubmit2(onSubmitChat2)}>
          <ChatContents ref={scrollableElementRef}>
            {chatHistory?.length === 0 &&
              questions?.map(
                (question, qIndex) =>
                  (question.id === questions[0]?.id ||
                    showQuestion.includes(question.id)) && (
                    <ChatQuestion key={question.id}>
                      <ChatMessage className="assistant">
                        <div className="message">{question.text}</div>
                      </ChatMessage>
                      <Select>
                        {question?.selects &&
                          question?.selects.map((select) => (
                            <div
                              className={`selectBox ${
                                selectId.some(
                                  (obj) =>
                                    obj[`question_${question.id}`] === select.id
                                ) && "selected"
                              } `}
                              key={select.id}
                              onClick={() => {
                                setAdditionalInfo(
                                  additionalInfo +
                                    `\n${question.text} : ${select.text}`
                                );
                                // 다른 select.id는 선택이 안되도록
                                const newSelect = {
                                  [`question_${question.id}`]: select.id,
                                };
                                const isInclude = selectId.filter((select) => {
                                  return Object.keys(select).includes(
                                    `question_${question.id}`
                                  );
                                });
                                console.log({ isInclude });
                                if (isInclude.length > 0) {
                                  const newSelectArr = selectId.map(
                                    (select) => {
                                      if (
                                        Object.keys(select).includes(
                                          `question_${question.id}`
                                        )
                                      ) {
                                        return newSelect;
                                      } else {
                                        return select;
                                      }
                                    }
                                  );
                                  setSelectId(newSelectArr);
                                } else {
                                  setSelectId([...selectId, newSelect]);
                                }
                                setShowQuestion([
                                  ...showQuestion,
                                  questions[qIndex + 1]?.id,
                                ]);
                                if (!questions[qIndex + 1]?.id) {
                                  setShowAlertMessage(true);
                                }
                              }}
                            >
                              {select.text}
                            </div>
                          ))}
                      </Select>
                    </ChatQuestion>
                  )
              )}
            {showAlertMessage && (
              <ChatMessage className="assistant">
                <div className="message">
                  선택이 완료되었습니다. 자유롭게 질문해주세요!
                </div>
              </ChatMessage>
            )}
            {chatHistory.map((message, i) => (
              <ChatMessage key={i} className={message.role}>
                <div className="message">{message.content}</div>
              </ChatMessage>
            ))}
            {/* {chatIsLoading && currentQuestion && (
              <ChatMessage className="user">
                <div className="message">{currentQuestion}</div>
              </ChatMessage>
            )} */}
            {chatIsLoading && (
              <ChatMessage className="assistant">
                <div className="loading">
                  <PulseLoader color="#36d7b7" />
                </div>
              </ChatMessage>
            )}
          </ChatContents>
          <ChatInput>
            <input type="text" {...register2("question")} autoComplete="off" />
            <button className="sendIcon">
              <IoMdSend />
            </button>
          </ChatInput>
        </ChatContainer>
      </InnerContainer>
    </Container>
  );
}

export default Chat;
