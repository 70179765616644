import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
  scale: 1.1;
`;

const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  .moveToLogin {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    p {
      background-color: transparent;
      font-size: 0.8rem;
      border: none;
      color: #959595;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`;

export default function Signup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, data)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        alert("회원가입에 성공했습니다.");
        navigate("/login");
      })
      .catch((error) => {
        // 에러 처리
        console.log(error);
        alert("회원가입에 실패했습니다. 다시 시도해주세요.");
        window.location.reload();
      });
  };
  console.log(errors);

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>회원가입</Title>

        <Input
          {...register("userId", { required: true })}
          placeholder="아이디"
          autoComplete="off"
        />
        {errors?.userId && <Error>아이디를 입력해주세요.</Error>}

        <Input
          type="password"
          {...register("password", { required: true })}
          placeholder="비밀번호"
          autoComplete="off"
        />
        {errors?.password && <Error>비밀번호를 입력해주세요.</Error>}
        <Input
          type="password"
          {...register("passwordConfirm", {
            validate: (value) =>
              value === getValues("password") ||
              "비밀번호가 일치하지 않습니다.",
          })}
          placeholder="비밀번호 확인"
          autoComplete="off"
        />
        {errors?.passwordConfirm && (
          <Error>{errors.passwordConfirm.message}</Error>
        )}

        <Button type="submit">회원가입</Button>
        <div className="moveToLogin">
          <p
            onClick={() => {
              navigate("/login");
            }}
          >
            로그인
          </p>
        </div>
      </Form>
    </Container>
  );
}
