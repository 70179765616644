import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

function PrivateRoute() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("token");

  if (isLoggedIn) {
    return <Outlet />;
  } else {
    console.log("로그인이 필요합니다.");
    alert("로그인이 필요합니다.");
    console.log({ isLoggedIn });
    return <Navigate to="/login" />;
  }
}

export default PrivateRoute;
