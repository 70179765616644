import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import carCrashIcon from "../assets/img/car-crash.png";
import chatbotIcon from "../assets/img/icon_chatbot.png";
import sirenIcon from "../assets/img/siren.png";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useMutation } from "react-query";
import { deleteBlockApi, updateBlockApi } from "../apis/blockApi";
import { createQuestionApi } from "../apis/question";

const Container = styled.div`
  background-color: ${(props) => props.theme.keyColor};
  border-radius: 0.5rem;
  padding: 1.4rem;
  color: #fff;
  height: 5rem;
  position: relative;
  .modifyDelete {
    position: absolute;
    right: 0;
    top: 0;
    /* z-index: 9; */
    .menuTree {
      font-weight: 700;
    }
    button {
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 1rem;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  cursor: pointer;
  .title {
    font-size: 1.1rem;
    font-weight: 600;
    /* padding: 1rem 0; */
  }
  .description {
    font-size: 0.75rem;
    font-weight: 200;
    margin-top: 0.3rem;
  }
  &.carCrash {
    .icon {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
    height: 8.5rem;
  }
  &.siren {
    .icon {
      margin-bottom: 0.5rem;
    }
    height: 8.5rem;
    .description {
      margin-top: 0;
      margin-bottom: 0.3rem;
    }
  }
  &.chatbot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.5rem;
    .description {
      margin-top: 0;
      margin-bottom: 0.3rem;
    }
  }
  &.channelTalk {
    height: 5.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    .description {
      margin-top: 0;
      margin-bottom: 0.3rem;
    }
  }
`;
const Contents = styled.div``;
const Icon = styled.div``;

const ModalContainer = styled.form`
  padding: 1rem;
  .header {
    display: flex;
    justify-content: flex-end;
    .icon {
      cursor: pointer;
      scale: 1.4;
    }
  }
  .inputGroup {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    h3 {
      width: 3rem;
      font-weight: 500;
    }
    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
  .completeBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    button {
      padding: 0.5rem 1rem;
    }
  }
`;
const ModalContainer2 = styled.div`
  padding: 1rem;
  .header {
    display: flex;
    justify-content: flex-end;
    .icon {
      cursor: pointer;
      scale: 1.4;
    }
  }
  .inputGroup {
    display: flex;
    align-items: center;
    flex-direction: column;
    .questionBox {
      margin-bottom: 1rem;
    }
    .newQuestion {
    }
    h3 {
      width: 3rem;
      font-weight: 500;
    }
    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
  .completeBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    button {
      padding: 0.5rem 1rem;
    }
  }
`;

function Block({
  title,
  description,
  blockId,
  iconType,
  isFixed,
  isAdmin,
  link,
  questions,
}) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  // Admin
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: `80vw`,
      // height: `50vh`,
      padding: 0,
      borderColor: "#000",
      backgroundColor: "rgb(245,243,243)",
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    console.log("dododo");
    setIsOpen(false);
  };
  // console.log({ link });

  // update block
  const { mutate: updateBlock } = useMutation(updateBlockApi, {
    onSuccess: (res) => {
      console.log(res);
      alert("수정되었습니다.");
      closeModal();
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const onSubmitUpdate = (inputData) => {
    updateBlock({ ...inputData, id: blockId });
  };

  // deleteBlock
  const { mutate: deleteBlock } = useMutation(deleteBlockApi, {
    onSuccess: (res) => {
      console.log(res);
      alert("삭제되었습니다.");
      closeModal();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [questionModalIsOpen, setQuestionOpen] = useState(false);
  const openQuestionModal = () => {
    setQuestionOpen(true);
  };
  const closeQuestionModal = () => {
    console.log("dododo");
    setQuestionOpen(false);
  };

  // create Question
  const { mutate: createQuestion } = useMutation(createQuestionApi, {
    onSuccess: (res) => {
      console.log(res);
      alert("추가되었습니다.");
      closeQuestionModal();
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const onSubmitQuestion = (inputData) => {
    console.log(inputData);
    createQuestion({
      question: inputData.question,
      blockId: blockId,
    });
  };

  return (
    <Container
      onClick={(e) => {
        if (!isAdmin && isFixed && iconType === "chatbot") {
          navigate(`/chat/${blockId}`);
        } else if (
          !isAdmin &&
          isFixed &&
          (iconType === "siren" || iconType === "carCrash")
        ) {
          navigate(`/vision/${blockId}`);
        } else if (isAdmin) {
          // navigate(`/admin/edit/${blockId}`);
          return;
        } else {
          window.open(link);
        }
      }}
      className={iconType}
    >
      {iconType === "siren" && (
        <Icon className="icon">
          <img src={sirenIcon} alt="siren" />
        </Icon>
      )}

      {iconType === "chatbot" ||
      iconType === "siren" ||
      iconType === "channelTalk" ? (
        <Contents>
          <p className="description">{description}</p>
          <h1 className="title">{title}</h1>
        </Contents>
      ) : (
        <Contents>
          <h1 className="title">{title}</h1>
          <p className="description">{description}</p>
        </Contents>
      )}

      {iconType === "chatbot" ? (
        <Icon className="icon">
          <img src={chatbotIcon} alt="chatbot" />
        </Icon>
      ) : iconType === "carCrash" ? (
        <Icon className="icon">
          <img src={carCrashIcon} alt="carCrash" />
        </Icon>
      ) : (
        ""
      )}
      {isAdmin && (
        <div className="modifyDelete">
          {isFixed && !link && (
            <button
              className="modifyBtn menuTree"
              onClick={() => {
                navigate(`/admin/edit/${blockId}`);
              }}
            >
              메뉴트리
            </button>
          )}
          <button className="modifyBtn" onClick={openModal}>
            수정
          </button>
          <button
            className="deleteBtn"
            onClick={() => {
              if (window.confirm("정말 삭제하시겠습니까?")) {
                deleteBlock(blockId);
              }
            }}
          >
            삭제
          </button>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <ModalContainer onSubmit={handleSubmit(onSubmitUpdate)}>
          <div className="header">
            <IoIosCloseCircleOutline className="icon" onClick={closeModal} />
          </div>
          <div className="inputBox">
            <div className="inputGroup">
              <h3>제목</h3>
              <input type="text" defaultValue={title} {...register("title")} />
            </div>
            <div className="inputGroup">
              <h3>설명</h3>
              <input
                type="text"
                defaultValue={description}
                {...register("description")}
              />
            </div>
            {link && (
              <div className="inputGroup">
                <h3>링크</h3>
                <input type="text" defaultValue={link} {...register("link")} />
              </div>
            )}
          </div>
          <div className="completeBtn">
            <button>완료</button>
          </div>
        </ModalContainer>
      </Modal>
      <Modal
        isOpen={questionModalIsOpen}
        style={customStyles}
        onRequestClose={closeQuestionModal}
      >
        <ModalContainer2>
          <div className="header">
            <IoIosCloseCircleOutline
              className="icon"
              onClick={closeQuestionModal}
            />
          </div>
          {isFixed && !link && (
            <div className="inputGroup">
              <div className="contents">
                {questions?.map((question) => (
                  <form className="questionBox" key={question.id}>
                    <h3>질문</h3>
                    <input
                      type="text"
                      defaultValue={question.text}
                      {...register("question")}
                      autoComplete="off"
                    />
                    <h4>선택지</h4>
                    <div className="selects">
                      {question?.selects?.map((select, i) => (
                        <input
                          type="text"
                          defaultValue={select?.text}
                          {...register(`select`)}
                          autoComplete="off"
                          key={select.id}
                        />
                      ))}
                    </div>
                    <div className="btnGroup">
                      <button></button>
                    </div>
                  </form>
                ))}
              </div>
              <form
                className="newQuestion"
                onSubmit={handleSubmit(onSubmitQuestion)}
              >
                <h3>질문</h3>
                <input
                  type="text"
                  {...register("question")}
                  autoComplete="off"
                />
                <div className="addQuestionBtn">
                  <button>질문 추가</button>
                </div>
              </form>
            </div>
          )}
        </ModalContainer2>
      </Modal>
    </Container>
  );
}

export default Block;
