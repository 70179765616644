import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// 스타일 정의
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
`;

const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  .moveToSignup {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    p {
      background-color: transparent;
      font-size: 0.8rem;
      border: none;
      color: #959595;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`;

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        navigate("/admin");
      })
      .catch((error) => {
        // 에러 처리
        if (error.response.status === 401) {
          reset();
          return alert("아이디 혹은 비밀번호가 일치하지 않습니다.");
        } else {
          alert("로그인에 실패했습니다.");
          window.location.reload();
          return;
        }
      });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>Login</Title>

        <Input
          {...register("userId", {
            required: true,
          })}
          placeholder="ID"
          autoComplete="off"
        />
        {errors?.userId && <Error>아이디를 입력해주세요.</Error>}

        <Input
          type="password"
          {...register("password", {
            required: true,
          })}
          placeholder="Password"
          autoComplete="off"
        />
        {errors?.password && <Error>비밀번호를 입력해주세요.</Error>}

        <Button type="submit">로그인</Button>
        {/* <div className="moveToSignup">
          <p
            onClick={() => {
              navigate("/signup");
            }}
          >
            회원가입
          </p>
        </div> */}
      </Form>
    </Container>
  );
}
