import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getBlocksApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/block`)
  ).data;
};
export const getFixedBlocksApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/block?isFixed=true`)
  ).data;
};
export const getNotFixedBlocksApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/block?isFixed=false`)
  ).data;
};
export const getBlockApi = async (blockId) => {
  return await (
    await axios.get(`${BASE_URL}/block/${blockId}`)
  ).data;
};

// create block
export const createBlockApi = async ({ title, description, link }) => {
  return await (
    await axios.post(`${BASE_URL}/block`, {
      title,
      description,
      link,
    })
  ).data;
};

// update block
export const updateBlockApi = async ({ id, title, description, link }) => {
  return await (
    await axios.put(`${BASE_URL}/block/${id}`, {
      title,
      description,
      link,
    })
  ).data;
};

// delete block
export const deleteBlockApi = async (id) => {
  return await (
    await axios.delete(`${BASE_URL}/block/${id}`)
  ).data;
};

// export const changeSeasonApi = async ({ season, startdate }) => {
//   return await (
//     await axios.post(`${BASE_URL}/currentInfo/changeSeason`, {
//       season,
//       startdate,
//     })
//   ).data;
// };
