import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./routes/Home";
import Chat from "./routes/Chat";
import Admin from "./routes/admin/Admin";
import SignUp from "./routes/SignUp";
import Login from "./routes/Login";
import PrivateRoute from "./routes/PrivateRoute";
import EditBlock from "./routes/admin/EditBlock";
import VisualChat from "./routes/VisualChat";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat/:blockId" element={<Chat />} />
        <Route path="/vision/:blockId" element={<VisualChat />} />
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/edit/:blockId" element={<EditBlock />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
