import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const chatApi = async ({ query, blockId, threadId, additionalInfo }) => {
  return await (
    await axios.post(
      `${BASE_URL}/chat`,
      // `https://x4x7cmy63fg2s777f2hgvbbiju0zooij.lambda-url.ap-northeast-2.on.aws/`,
      {
        query,
        blockId,
        threadId,
        additionalInfo,
      },
      {
        timeout: 200000,
      }
    )
  ).data;
};
export const getAllMessagesApi = async (threadId) => {
  return await (
    await axios.get(`${BASE_URL}/chat?threadId=${threadId}`)
  ).data;
};
export const deleteThreadApi = async (threadId) => {
  return await (
    await axios.delete(`${BASE_URL}/chat?threadId=${threadId}`)
  ).data;
};
export const visionChatApi = async ({
  query,
  image,
  chatHistory,
  additionalInfo,
}) => {
  return await (
    await axios.post(
      `${BASE_URL}/chat/vision`,
      {
        query,
        image,
        chatHistory,
        additionalInfo,
      },
      {
        timeout: 200000,
      }
    )
  ).data;
};
