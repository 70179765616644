import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import dayjs from "dayjs";
import {
  chatApi,
  deleteThreadApi,
  getAllMessagesApi,
  visionChatApi,
} from "../apis/chat";
import { queryKey } from "../utils/queryKey";
import { LuRefreshCw } from "react-icons/lu";
import { IoMdSend } from "react-icons/io";
import { set, useForm } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { getBlockApi } from "../apis/blockApi";
import { useParams } from "react-router-dom";
import { MdAddToPhotos } from "react-icons/md";
import AWS from "aws-sdk";
import axios from "axios";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;
const ChatHeader = styled.div`
  background-color: ${(props) => props.theme.keyColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  width: ${(props) => props.theme.mobileWidth};
  overflow: hidden;
  z-index: 9;
  @media screen and (max-width: ${(props) => props.theme.mobileWidth}) {
    width: 100%;
  }
  height: ${(props) => props.theme.headerHeight};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  h1.title {
    color: #fff;
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
  }
  .refreshIcon {
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .deleting {
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation: rotate 1.5s linear infinite;
  }
`;
const InnerContainer = styled.div`
  background-color: #f7f8ff;
  min-height: 100vh;
  width: ${(props) => props.theme.mobileWidth};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @media screen and (max-width: ${(props) => props.theme.mobileWidth}) {
    width: 100%;
  }
`;
const ChatContainer = styled.form`
  overflow: hidden;
`;
const ChatContents = styled.div`
  /* background-color: red; */
  max-height: calc(
    98vh -
      (
        ${(props) => props.theme.headerHeight} +
          ${(props) => props.theme.footerHeight}
      )
  );
  overflow-y: scroll;
  margin-top: ${(props) => props.theme.headerHeight};
`;
const ChatMessage = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.className === "user" ? "flex-end" : "flex-start"};
  margin: 1rem;
  position: relative;
  font-size: 0.9rem;
  .loading {
    scale: 0.8;
  }
  .message {
    border-radius: 0.5rem;
    padding: 0.5rem 0.7rem;
    line-height: 1.1rem;
  }
  &.user {
    .message {
      background-color: ${(props) => props.theme.keyColor};
      padding: 0.3rem 0.6rem;
      color: #fff;
      /* 행간 넓히기 */
      line-height: 1.5rem;
    }
  }
  .imageMessage {
    width: 10rem;
    margin-bottom: -0.7rem;
  }
  &.assistant {
    .message {
      background-color: #d7d7d7;
      /* padding: 1rem; */
      /* 행간 넓히기 */
    }
  }
`;
const ChatImage = styled.div`
  position: absolute;
  bottom: 4.3rem;
  right: 1rem;
  animation: float 3s ease-in-out infinite;
  img {
    width: 5rem;
  }
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-0.3rem);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
const ChatInput = styled.div`
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  background-color: #fff;
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 1.5vh auto;
  width: ${(props) => props.theme.mobileWidth};
  height: ${(props) => props.theme.footerHeight};
  @media screen and (max-width: ${(props) => props.theme.mobileWidth}) {
    width: 95%;
  }
  .attachPhoto {
    color: ${(props) => props.theme.keyColor};
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 0.3rem;
  }
  .sendIcon {
    color: ${(props) => props.theme.keyColor};
    font-size: 1.5rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }
  input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    background-color: inherit;
  }
`;

const ChatQuestion = styled.div`
  font-size: 0.9rem;
`;
const Select = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-row-gap: 0.5rem;
  grid-template-columns: auto auto auto;
  justify-content: center;
  margin-bottom: 2rem;
  .selectBox {
    background-color: transparent;
    text-align: center;
    padding: 0.6rem;
    border-radius: 0.5rem;
    border: 1.5px solid ${(props) => props.theme.keyColor};
    color: ${(props) => props.theme.keyColor};
    font-weight: 600;
    margin: 0 0.5rem;
    &.selected {
      background-color: ${(props) => props.theme.keyColor};
      color: #fff;
    }
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.keyColor};
      color: #fff;
    }
  }
`;

function VisualChat() {
  const { register, handleSubmit, reset, getValues, resetField } = useForm();
  const blockId = useParams()?.blockId;
  const [currentQuery, setCurrentQuery] = useState("");

  const [messages, setMessages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    // 이미지 파일만 업로드 가능
    console.log("호호호호");
    console.log(e.target.files[0]);
    if (!e.target.files[0].type.includes("image")) {
      return alert("이미지 파일만 업로드 가능합니다.");
    }
    const files = Array.from(e.target.files); // FileList를 배열로 변환
    setSelectedFiles(files);
    setImageUrl(URL.createObjectURL(files[0]));
  };

  // S3 관련
  // Chat 관련
  const queryClient = useQueryClient();
  const { mutate: postChat, isLoading: runIsGoing } = useMutation(
    visionChatApi,
    {
      onSuccess: (res) => {
        console.log("하하하하하", ...messages);
        setMessages([
          ...messages,
          {
            role: "user",
            content: res.query,
          },
          {
            role: res.role,
            content: res.content,
          },
        ]);
        setImageUrl("");
        setSelectedFiles([]);
      },
      onError: (err) => {
        console.log(err);
        alert(
          "지원하지 않는 이미지 파일 형태입니다. (png, jpeg, gif, webp만 가능)"
        );
        return;
      },
    }
  );

  // form 관련
  const onSubmitChat = async (inputData) => {
    // console.log(inputData);
    const { query } = inputData;
    let data = {};
    console.log({ selectedFiles });

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      formData.append("file", selectedFiles[0]);

      const { s3Url } = await (
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/chat/upload/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
      ).data;
      setMessages([
        ...messages,
        {
          role: "user",
          content: [
            {
              type: "text",
              text: query,
            },
          ],
        },
      ]);
      setCurrentQuery(query);
      reset();
      postChat({
        query,
        image: s3Url,
        chatHistory: messages,
      });
    } else {
      setMessages([
        ...messages,
        {
          role: "user",
          content: [
            {
              type: "text",
              text: query,
            },
          ],
        },
      ]);
      setCurrentQuery(query);
      reset();
      postChat({
        query,
        chatHistory: messages,
      });
    }
  };
  console.log({ messages });
  console.log({ currentQuery });

  // 블록 정보
  const { data: blockData } = useQuery(queryKey.blockKey.getBlock, () =>
    getBlockApi(blockId)
  );

  // 항상 맨 아래로 스크롤되게
  const scrollableElementRef = useRef(null);
  useEffect(() => {
    if (scrollableElementRef.current) {
      scrollableElementRef.current.scrollTop =
        scrollableElementRef.current.scrollHeight;
    }
  }, [messages, onSubmitChat]);

  // 사전 질문 관련
  const questions = blockData && blockData.block.questions;
  // console.log({ questions });
  const [showQuestion, setShowQuestion] = useState([
    questions && questions[0]?.id,
  ]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selectId, setSelectId] = useState([]);
  // console.log({ additionalInfo });

  const [showAlertMessage, setShowAlertMessage] = useState(false);

  return (
    <Container>
      <InnerContainer>
        <ChatHeader>
          <h1 className="title">{blockData?.block.title}</h1>
        </ChatHeader>
        <ChatContainer onSubmit={handleSubmit(onSubmitChat)}>
          <ChatContents ref={scrollableElementRef}>
            {questions?.map(
              (question, qIndex) =>
                (question.id === questions[0].id ||
                  showQuestion.includes(question.id)) && (
                  <ChatQuestion key={qIndex}>
                    <ChatMessage className="assistant">
                      <div className="message">{question.text}</div>
                    </ChatMessage>
                    <Select>
                      {question?.selects &&
                        question?.selects.map((select, i) => (
                          <div
                            className={`selectBox ${
                              selectId?.includes(select.id) && "selected"
                            } `}
                            key={i}
                            onClick={() => {
                              setAdditionalInfo(
                                additionalInfo +
                                  `\n${question.text} : ${select.text}`
                              );
                              setSelectId([...selectId, select.id]);
                              setShowQuestion([
                                ...showQuestion,
                                questions[qIndex + 1]?.id,
                              ]);
                              if (!questions[qIndex + 1]?.id) {
                                setShowAlertMessage(true);
                              }
                            }}
                          >
                            {select.text}
                          </div>
                        ))}
                    </Select>
                  </ChatQuestion>
                )
            )}
            {showAlertMessage && (
              <ChatMessage className="assistant">
                <div className="message">
                  선택이 완료되었습니다. 자유롭게 질문해주세요!
                </div>
              </ChatMessage>
            )}
            {messages?.map((message, i) => (
              <div key={message.id}>
                {message?.role === "user" &&
                  message?.content[1]?.type === "image_url" && (
                    <ChatMessage className={message.role}>
                      <img
                        className="imageMessage"
                        src={message?.content[1]?.image_url.url}
                        alt="img"
                      />
                    </ChatMessage>
                  )}
                <ChatMessage key={i} className={message.role}>
                  <div className="message">
                    {message?.role === "user"
                      ? message.content[0].text
                      : message.content}
                  </div>
                </ChatMessage>
              </div>
            ))}

            {/* loading */}
            {runIsGoing && (
              <ChatMessage className="assistant">
                <div className="loading">
                  <PulseLoader color="#36d7b7" />
                </div>
              </ChatMessage>
            )}
          </ChatContents>
          {imageUrl && (
            <ChatImage>
              <img src={imageUrl} alt="image" />
            </ChatImage>
          )}
          <ChatInput>
            <label className="attachPhoto" htmlFor="fileUploadBtn">
              <MdAddToPhotos />
            </label>
            <input
              type="file"
              id="fileUploadBtn"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <input type="text" {...register("query")} autoComplete="off" />
            <button className="sendIcon">
              <IoMdSend />
            </button>
          </ChatInput>
        </ChatContainer>
      </InnerContainer>
    </Container>
  );
}

export default VisualChat;
