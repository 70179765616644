import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getQuestionsApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/question`)
  ).data;
};

// create question and selects
export const createQuestionApi = async ({ text, blockId }) => {
  return await (
    await axios.post(`${BASE_URL}/question`, {
      text,
      blockId,
    })
  ).data;
};

// update question
export const updateQuestionApi = async ({ id, text }) => {
  return await (
    await axios.put(`${BASE_URL}/question/${id}`, {
      text,
    })
  ).data;
};

// delete question
export const deleteQuestionApi = async (id) => {
  return await (
    await axios.delete(`${BASE_URL}/question/${id}`)
  ).data;
};
